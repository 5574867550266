import React from 'react';

const footer2 = () => {
  const footer2 = {
    height: "3vh",
    width: "100%",
    position: "absolute",
    bottom: "0",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0)",
    color: "white"
  };

  return (
    <div style={footer2}>
      Think Mining 2024
    </div>
  );
};

export default footer2;
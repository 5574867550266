import React, { useState } from 'react';
import './inicio.css';
import Banner2 from './Componentes/banner2';
import Footer2 from './Componentes/footer2';
import Sidebar from './Componentes/sidebar';
import smartroad from './Imagenes/smartroad.jpg'
import smartroad2 from './Imagenes/smartroad2.jpg'
import smartroad3 from './Imagenes/smartroad3.jpg'
import riego from './Imagenes/riego.jpg'
import riego2 from './Imagenes/riego2.jpg'
import riego3 from './Imagenes/riego3.jpg'
import cabina from './Imagenes/cabina.jpg'
import cabina2 from './Imagenes/cabina2.jpg'
import cabina3 from './Imagenes/cabina3.jpg'
import supresion from './Imagenes/supresion.jpg'
import supresion2 from './Imagenes/supresion2.jpg'
import supresion3 from './Imagenes/supresion3.jpg'
import monitoreo from './Imagenes/monitoreo.jpg'
import monitoreo2 from './Imagenes/monitoreo2.jpg'
import monitoreo3 from './Imagenes/monitoreo3.jpg'

const Inicio = () => {

    const [backgroundImage, setBackgroundImage] = useState('');

    const handleMouseOver = (image) => {
      setBackgroundImage(image);
    };

    const handleMouseOut = () => {
        setBackgroundImage("");
    };

  return (

    <div class="bodyprueba" id="bodyprueba" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>

    <Banner2/>
    <Sidebar/>


    <div class="mainprueba">

        <div id="cardsmart" onClick={() => window.location.href = "https://antucoya.smartroad.eye3.cl/#/login"} class="card" onMouseOver={() => handleMouseOver(smartroad)} onMouseOut={handleMouseOut}>
            <img src={smartroad2} id="pc" class="image" alt="taj" ></img>
            <img src={smartroad3} id="mv" class="image" alt="taj" ></img>
            <img src={smartroad} class="background" alt="taj"></img>
            <div class="layer">
                <div class="info">
                    <h1>Smart Road</h1>
                </div>
                <div class="info2">
                    <h1>Smart Road</h1>
                </div>
            </div>
        </div>

        <div id="cardriego" onClick={() => window.location.href = "./riego"} class="card" onMouseOver={() => handleMouseOver(riego)} onMouseOut={handleMouseOut}>
            <img src={riego2} id="pc" class="image" alt="marina" ></img>
            <img src={riego3} id="mv" class="image" alt="marina" ></img>
            <img src={riego} class="background" alt="marina"></img>
            <div class="layer">
                <div class="info">
                    <h1>Sistema de Riego</h1>
                </div>
                <div class="info2">
                    <h1>Sistema de Riego</h1>
                </div>
            </div>
        </div>

        <div id="cardcabina" onClick={() => window.location.href = "./cabina"} class="card" onMouseOver={() => handleMouseOver(cabina)} onMouseOut={handleMouseOut}>
            <img src={cabina2} id="pc" class="image" alt="wall"></img>
            <img src={cabina3} id="mv" class="image" alt="wall"></img>
            <img src={cabina} class="background" alt="wall"></img>
            <div class="layer">
                <div class="info">
                    <h1>Sensor de Cabina</h1>
                </div>
                <div class="info2">
                    <h1>Sensor de Cabina</h1>
                </div>
            </div>
        </div>

        <div id="cardsupresion" class="card" onMouseOver={() => handleMouseOver(supresion)} onMouseOut={handleMouseOut}>
            <img src={supresion2} id="pc" class="image" alt="liberty"></img>
            <img src={supresion3} id="mv" class="image" alt="liberty"></img>
            <img src={supresion} class="background" alt="liberty"></img>
            <div class="layer">
                <div class="info">
                    <h1>Supresión de Polvo</h1>
                </div>
                <div class="info2">
                    <h1>Supresión de Polvo</h1>
                </div>
            </div>
        </div>

        <div id="cardmonitoreo" class="card" onMouseOver={() => handleMouseOver(monitoreo)} onMouseOut={handleMouseOut}>
            <img src={monitoreo2} id="pc" class="image" alt="fuji"></img>
            <img src={monitoreo3} id="mv" class="image" alt="fuji"></img>
            <img src={monitoreo} class="background" alt="fuji"></img>
            <div class="layer">
                <div class="info">
                    <h1>Monitoreo RPAS</h1>
                </div>
                <div class="info2">
                    <h1>Monitoreo RPAS</h1>
                </div>
            </div>
        </div>

    </div>
    <Footer2/>
</div>

  );
};

export default Inicio;
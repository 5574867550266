import { useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate} from "react-router-dom";
import LoginForm from "./loginform";
import Inicio from "./inicio";
import Riego from "./riego";
import Cabina from "./cabina"
import ResumenCabina from "./cabinaRESUMEN"
import NewEquipo from "./nuevoequipo"
import NewChip from "./nuevochip"
import Listadoequipos from "./listadoequipos"
import Listadochips from "./listadochips"
import Firstlogin from "./Componentes/firstlogin"
import Tablet from "./tablet"
import Prueba from "./prueba"


function App() {

  
 // Verificar el número de rol del usuario al cargar la aplicación
 const authToken = parseInt(sessionStorage.getItem("rol"));
  
 // Función para verificar si el usuario tiene acceso al componente
 const checkAccess = (allowedRoles, currentRole) => {
   return allowedRoles.includes(currentRole);
 }

  useEffect(() => {
    // Título de la página
    document.title = "Think Mining";
    }
  );

  return (
    <BrowserRouter>
      <Routes>
        
        {authToken ? (
          <>
            {checkAccess([0, 1, 2, 3], authToken) && <Route path="/riego" element={<Riego />} />}
            {checkAccess([0, 1, 2, 4], authToken) && <Route path="/cabina" element={<Cabina />} />}
            {checkAccess([0, 1], authToken) && <Route path="/cabresum" element={<ResumenCabina />} />}
            {checkAccess([0, 1], authToken) && <Route path="/nuevoequipo" element={<NewEquipo />} />}
            {checkAccess([0, 1], authToken) && <Route path="/prueba" element={<Prueba />} />}
            {checkAccess([0, 1], authToken) && <Route path="/nuevochip" element={<NewChip />} />}
            {checkAccess([0, 1], authToken) && <Route path="/listadoequipos" element={<Listadoequipos />} />}
            {checkAccess([0, 1], authToken) && <Route path="/listadochips" element={<Listadochips />} />}
            {checkAccess([0, 1, 2, 3, 4, 5], authToken) && <Route path="/firstlogin" element={<Firstlogin />} />}
            {checkAccess([0, 1, 5], authToken) && <Route path="/tablet" element={<Tablet />} />}
            <Route path="/inicio" element={<Inicio />} />
            <Route path="/" element={<Inicio />}/>
            <Route path="*" element={<Inicio />}/>
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/"/>} />
            <Route path="/" element={<LoginForm/>} />
            <Route path="/cabinav2" element={<ResumenCabina/>} />
            <Route path="/imagenes/*" element={<Navigate to="./"/>} />
            <Route path="/documentos/*" element={<Navigate to="./"/>} />
          </>
        )}

      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import moment from 'moment';
import editar from '../Imagenes/edit.png'
import '../listado.css'
import '../formulario.css'

const SearchComponent = () => {
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState("")
  const [selectedItem, setSelectedItem] = useState(null)

  const URL = 'http://18.207.128.95:8080/api/ficha/getchips'

  const showData = async () => {
    const response = await fetch(URL)
    const data = await response.json()
    setUsers(data)
  }

  const searcher = (e) => {
      setSearch(e.target.value)   
  }

  const results = !search ? users : users.filter((dato) => {
      return Object.values(dato).some(value =>
          value.toString().toLowerCase().includes(search.toLowerCase())
      )
  })

  const [formData, setFormData] = useState({
    idchip: '',
    numero_telefono: '',
    estado: '',
    empresa_chip: '',
    imei: ''
    })

  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
  };

  const editHandler = (idchip) => {
    document.getElementById("updatechips").style.display = "grid";
    document.getElementById("tablachips").style.display = "none";
    document.getElementById("buscarchips").style.display = "none";
    const selectedUser = users.find(user => user.idchip === idchip)
    setSelectedItem(selectedUser);

    // Setear los valores seleccionados en el formData
    setFormData({
      idchip: selectedUser.idchip,
      empresa_chip: selectedUser.idempresa_chip,
      estado: selectedUser.idestado
    });

  }

  const cancelar = () => {
    window.location.href = "/listadochips";
  }

  const [empresa, setempresa] = useState([]);
    useEffect(() => {
      fetch('http://18.207.128.95:8080/api/ficha/getempresachip')
        .then(response => response.json())
          .then(data => {
            setempresa(data);
          })
          .catch(error => {
            console.error('Error al obtener los responsables de la API:', error);
          });
    }, []);

    const [estadoequipo, setestadoequipo] = useState([]);
    useEffect(() => {
      fetch('http://18.207.128.95:8080/api/ficha/getestadochips')
        .then(response => response.json())
          .then(data => {
            setestadoequipo(data);
          })
        .catch(error => {
          console.error('Error al obtener los responsables de la API:', error);
        });
    }, []);

  useEffect(() => {
    showData()
  }, [])

  const enviar = (e) => {
    e.preventDefault();
      if ( formData.empresa_chip === ''){
        Swal.fire({
          title: "Rellene los campos faltantes!",
          confirmButtonText: 'OK',
          icon: "error",
        })
      }else{
      Swal.fire({
        title: "Quieres guardar los cambios?",
        showCancelButton: true,
        confirmButtonText: "Guardar", 
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit();
        }
      });  
      } 
  }

  const handleSubmit = async (e) => {
    try {
      const response = await fetch('http://18.207.128.95:8080/api/ficha/updateChip', {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        Swal.fire({
          title: "Guardado!",
          confirmButtonText: '<a href="./listadochips">OK</a>',
          icon: "success",
        })
      } else {
        Swal.fire({
          title: "Error al guardar los datos!",
          confirmButtonText: 'Atras',
          icon: "error",
        })
      }
    } catch (error) {
      console.error('Error al guardar los datos: ', error);
      Swal.fire({
        title: "Error al enviar los datos!",
        confirmButtonText: 'Atras',
        icon: "error",
      })
    }
  };

  const fechaFormateada = (fecha) => {
    return moment(fecha).format('YYYY-MM-DD HH:mm');
  };

  const [valuenro, setValue] = useState('');
  const handleChange2 = (e) => {
  const newValue = e.target.value.replace(/[^\d]/g, ''); // Remueve todos los caracteres que no sean dígitos
  setValue(newValue);
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value
  });
};

const [valuenro2, setValue2] = useState('');
const handleChange3 = (e) => {
  const newValue2 = e.target.value.replace(/[^\d]/g, ''); // Remueve todos los caracteres que no sean dígitos
  setValue2(newValue2);
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value
  });
};

  return (

    <div className='container'>

      <div className='updatechips' id='updatechips'>

      <div className="ordenar" >
            <label>EMPRESA:</label>
              <select id="estilo" name="empresa_chip" onChange={handleChange}>
                <option value={formData.empresa_chip} disabled selected hidden>{selectedItem?.empresa_chip}</option>
                {empresa.map(empresa => (
                <option key={empresa.id_empresa} value={empresa.idempresa_chip}>{empresa.nombre}</option>))}
              </select>
            </div>

            <div className="ordenar">
                <label for="empresa">N° IDENTIFICADOR DE CHIP:</label>
                <input name="numero_telefono" value={valuenro} id="estilo" type="text" onChange={handleChange2}/>
            </div>

            <div className="ordenar">
                <label for="empresa">N° DE TELEFONO DEL CHIP:</label>
                <input name="imei" value={valuenro2} id="estilo" type="text" onChange={handleChange3}/>
            </div>

                <div className="ordenar" >
                <label>ESTADO DEL EQUIPO:</label>
                <select id="estilo" name="estado" onChange={handleChange}>
                <option value={formData.estadoequipo} disabled selected hidden>{selectedItem?.estado}</option>
                {estadoequipo.map(estadoequipo => (
                <option key={estadoequipo.id_estadoequipo} value={estadoequipo.idestado}>{estadoequipo.tipoestado}</option>))}
                </select>
                </div>

                <div className="formularionuevoequipo-2">

                <button type="Submit" className="boton" onClick={cancelar}>Cancelar</button>
                <button type="Submit" className="boton" onClick={enviar}>Guardar</button>

                </div>

      </div>

      <input id='buscarchips' value={search} onChange={searcher} type="text" placeholder='Buscar' className='form-control'/>
      <table id='tablachips' className='table table-striped table-hover mt-1 shadow-lg'>
        <thead>
          <tr className='bg-curso text-black'>
            <th>IMEI</th>
            <th>NUMERO DE TELEFONO</th>
            <th>EMPRESA</th>
            <th>ULTIMA ACTUALIZACION</th>
            <th>ESTADO</th>
            <th>EDITAR</th>
          </tr>
        </thead>
        <tbody>
          {results.map((user) => (
            <tr key={user.id}>
              <td>{user.imei}</td>
              <td>{user.numero_telefono}</td>
              <td>{user.empresa_chip}</td>
              <td>{fechaFormateada(user.fecha_ult_modific)}</td>
              <td className={user.estado === 'ACTIVO INSTALADO' ? 'text-success' : user.estado === 'ACTIVO DESINSTALADO' ? 'text-primary' : 'text-muted'} style={{ borderRadius:"15px"}}>{user.estado}</td>
              <td>
              <div onClick={() => editHandler(user.idchip)}><img src={editar}  style={{ height:"20px", width:"20px", cursor:"pointer"}} alt="Editar" /></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SearchComponent
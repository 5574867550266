import { color } from "d3-color";
import React, { Component } from "react";
import LiquidFillGauge from "react-liquid-gauge";

class Tank extends Component {
  state = {
    value: 0
  };
  startColor = "#6495ed";
  middleColor = "#ffa500";
  endColor = "#dc143c";

  componentDidMount() {
    this.setState({ value: this.props.value });

    this.interval = setInterval(() => {
      if (this.props.value !== this.state.value) {
        this.setState({ value: this.props.value });
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const radius = this.props.radius;
    let fillColor = this.startColor;
    if (this.state.value >= 75 && this.state.value < 85) {
      fillColor = this.middleColor;
    } else if (this.state.value >= 85) {
      fillColor = this.endColor;
    }

    return (
      <div>
        <LiquidFillGauge
          style={{ margin: "0 auto" }}
          width={radius * 2}
          height={radius * 2}
          value={this.state.value}
          percent="%"
          textSize={1}
          textOffsetX={0}
          textOffsetY={0}
          textRenderer={props => {
            const value = Math.round(props.value);
            const radius = Math.min(props.height / 2, props.width / 2);
            const textPixels = (props.textSize * radius) / 2;
            const valueStyle = {
              fontSize: textPixels
            };
            const percentStyle = {
              fontSize: textPixels * 0.6
            };

            return (
              <tspan>
                <tspan className="value" style={valueStyle}>
                  {value}
                </tspan>
                <tspan style={percentStyle}>{props.percent}</tspan>
              </tspan>
            );
          }}
          riseAnimation
          waveAnimation
          waveFrequency={1}
          waveAmplitude={5}
          circleStyle={{
            fill: fillColor
          }}
          waveStyle={{
            fill: fillColor
          }}
          textStyle={{
            fill: color("#444").toString(),
            fontFamily: "Arial"
          }}
          waveTextStyle={{
            fill: color("#fff").toString(),
            fontFamily: "Arial"
          }}
        />
      </div>
    );
  }
}

export default Tank;
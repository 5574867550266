import React, { useState, useEffect } from 'react';
import './cabina.css';
import Banner from './Componentes/banner'
import MP from "./Graficos/MP"
import MPM from "./Graficos/MPM"
import G from "./Graficos/G"
import GM from "./Graficos/GM"
import A from "./Graficos/A"
import AM from "./Graficos/AM"
import cerrar from './Imagenes/cerrar.png'
import Swal from 'sweetalert2'
import moment from 'moment';
import Sidebar from './Componentes/sidebar'
import excel from './Imagenes/excel.png'
import CirclesToRhombusesSpinner from './Componentes/loading2'

const Cabina = () => {

  const Home = () => {

    window.location.href = "/inicio";
  
  };

//CONSTANTES PARA ID'S DE PERFORADORAS O CAEX (ESCRIBIR EL ID DEL EQUIPO PRESENTE EN LA BD)

const perforadora1 = 963;
const perforadora2 = 612;
const perforadora3 = 1;
const perforadora4 = 834;
const perforadora5 = 525;
const caex1 = 1;
const caex2 = 1;
const caex3 = 851;
const caex4 = 1030;
const caex5 = 1029;
const caex13 = 962;
const caex21 = 1007;
const caex23 = 673;
const caex29 = 1025;
const caex28 = 957;

//FIN CONSTANTES PARA ID'S DE PERFORADORAS O CAEX


//INICIO PARA SELECCIONES CHECKBOX Y SELECT LISTA DE EQUIPOS

const [selectedOption, setSelectedOption] = useState(null);

const handleChange = (value) => {
  setSelectedOption(value);
  setEnviado(false)
}

const [selectedOption2, setSelectedOption2] = useState('opcion 0');
const [selectedEquipo, setSelectedEquipo] = useState(null);
const [equipoexcel, setEquipoExcel] = useState(null);

const handleChange2 = (e) => {
  setSelectedOption2(e.target.value);
  setEnviado(false)
  reiniciarEquipo(); // Reiniciar el estado del equipo antes de hacer la solicitud al API
  switch (e.target.value) {

      case "opcion 1":
        setSelectedEquipo(perforadora1);
        setEquipoExcel("PERFORADORA 1 ")
      break;

      case "opcion 2":
        setSelectedEquipo(perforadora2);
        setEquipoExcel("PERFORADORA 2 ")
      break;

      case "opcion 3":
        setSelectedEquipo(perforadora3);
        setEquipoExcel("PERFORADORA 3 ")
      break;

      case "opcion 4":
        setSelectedEquipo(perforadora4);
        setEquipoExcel("PERFORADORA 4 ")
      break;

      case "opcion 5":
        setSelectedEquipo(perforadora5);
        setEquipoExcel("PERFORADORA 5 ")
      break;

      case "opcion 6":
        setSelectedEquipo(caex1);
        setEquipoExcel("CAEX 1 ")
      break;

      case "opcion 7":
        setSelectedEquipo(caex2);
        setEquipoExcel("CAEX 2 ")
      break;

      case "opcion 8":
        setSelectedEquipo(caex3);
        setEquipoExcel("CAEX 3 ")
      break;

      case "opcion 9":
        setSelectedEquipo(caex4);
        setEquipoExcel("CAEX 4 ")
      break;

      case "opcion 10":
        setSelectedEquipo(caex23);
        setEquipoExcel("CAEX 23 ")
      break;

      case "opcion 11":
        setSelectedEquipo(caex21);
        setEquipoExcel("CAEX 21 ")
      break;

      case "opcion 12":
        setSelectedEquipo(caex29);
        setEquipoExcel("CAEX 29 ")
      break;
      
      case "opcion 13":
        setSelectedEquipo(caex28);
        setEquipoExcel("CAEX 28 ")
      break;

      case "opcion 14":
        setSelectedEquipo(caex13);
        setEquipoExcel("CAEX 13 ")
      break;

      case "opcion 15":
        setSelectedEquipo(caex5);
        setEquipoExcel("CAEX 5 ")
      break;
      
      default:
        setSelectedEquipo(null);
      }
    };
    
    const reiniciarEquipo = () => {
      setEquipo({});
    }
    
    const [selectedOption3, setSelectedOption3] = useState('op1');
    
    const handleChange3 = (event) => {
      setSelectedOption3(event.target.value);
      setEnviado(false)
    }

//FIN PARA SELECCIONES CHECKBOX Y SELECT LISTA DE EQUIPOS

// INICIO DE APIS PARA EQUIPOS PERFORADORES Y CAEX

const url_base = 'https://www.mine-360.com/api/cabina/sensor/consultcabina';

  const [equipo, setEquipo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url_base, { 
          method: "POST",
          body:JSON.stringify({"equipo": selectedEquipo}),
          headers: {"Content-Type": "application/json"}
        })
        const data = await response.json();

        setEquipo({
          PPM1: data.promedioPm1.toFixed(2),
          PPM10: data.promedioPm10.toFixed(2),
          PPM25: data.promedioPm25.toFixed(2),
          PCO2: data.promedioCo2.toFixed(2),
          PVOC: Math.round(data.promedioVoc),
          PCH2O: data.promedioCh2o.toFixed(2),
          PCO: data.promedioCo.toFixed(2),
          PO3: data.promediOo3.toFixed(2),
          PNO2: data.promedioNo2.toFixed(2),
          PTEMP: data.promedioTemp.toFixed(2),
          PHUM: data.promedioHum.toFixed(2),
          PRUIDO: (30 + ((data.promedioRuido-30)*9)).toFixed(2),

        });
      } catch (error) {
        
      }
    };

    fetchData();
    
    const interval = setInterval(fetchData, 10000);
    
    return () => {
      clearInterval(interval);
    };
  }, [selectedEquipo]);

// FIN DE APIS PARA EQUIPOS

// INICIO HORA

    const [date, setDate] = useState(new Date());
  
    useEffect(() => {
      const interval = setInterval(() => {
        setDate(new Date());
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);
  
    const formatDate = (date) => {
      return date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: '-03',
      });
    };
  
    const formatTime = (date) => {
      return date.toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: '-04',
        hour12: false,
      });
    };

// FIN HORA

// INICIO COLOR DE DIV

const getColorPM1 = (value) => { let backgroundColor, color;
  if (value >= 0 && value < 10) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 10 && value <= 25) { backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) { backgroundColor = 'white'; color = 'black';
  } else { backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPM25 = (value) => { let backgroundColor, color;
  if (value >= 0 && value < 25) { backgroundColor = 'green'; color = 'white';
  } else if (value >= 25 && value <= 50) { backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) { backgroundColor = 'white'; color = 'black';
  } else { backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPM10 = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 50) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 50 && value <= 100) { backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPCO2 = (value) => { let backgroundColor = ''; let color = '';
  if (value >= 0 && value < 500) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 500 && value <= 700) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPVOC = (value) => { let backgroundColor, color;
  if (value >= 0 && value <= 1) {backgroundColor = 'green'; color = 'white';
  } else if (value > 1 && value <= 2) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined ){backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPCH2O = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 1) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 1 && value <= 3) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPCO = (value) => { let backgroundColor, color;
  if (value >= 0 && value < 0.6) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 0.6 && value <= 1) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color};}

const getColorPO3 = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 0.1) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 0.1 && value <= 0.2) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined){backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPNO2 = (value) => { let backgroundColor = ''; let color = '';
  if (value >= 0 && value < 0.15) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 0.15 && value <= 0.2) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined ){backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPTEMP = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 25) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 25 && value <= 35) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

const getColorPHUM = (value) => { let backgroundColor, color;
  if (value >= 0 && value < 50) {backgroundColor = 'green'; color = 'white';
  } else if (value >= 50 && value <= 75) {backgroundColor = 'yellow'; color = 'black';
  } else if (value === null || value === undefined) {backgroundColor = 'white'; color = 'black';
  } else {backgroundColor = 'red'; color = 'white';}
  return { backgroundColor, color };}

  const getColorPRUIDO = (value) => { let backgroundColor; let color;
  if (value >= 0 && value < 55) {backgroundColor = 'green'; color = 'white';
    } else if (value >= 55 && value <= 100) {backgroundColor = 'yellow'; color = 'black';
    } else if (value === null || value === undefined ){backgroundColor = 'white'; color = 'black';
    } else {backgroundColor = 'red'; color = 'white';}
    return { backgroundColor, color };}

// FIN COLOR DE DIV

// INICIO MODO MANUAL

const [fechaInicio, setFechaInicio] = useState('');
const [fechaFin, setFechaFin] = useState('');

const handleFechaInicioChange = (e) => {
  setFechaInicio(e.target.value);
  setEnviado(false)
}

const handleFechaFinChange = (e) => {
  setFechaFin(e.target.value);
  setEnviado(false)
}


//HORARIO VERANO U HORARIO INVIERNO. AGREGAR 4 HORAS O 3 HORAS DEPENDIENDO EL CASO.

const fechai = moment(fechaInicio, 'YYYY/MM/DD HH:mm:ss').add(4, 'hours').format('YYYY/MM/DD HH:mm:ss'); 
const fechaf = moment(fechaFin, 'YYYY/MM/DD HH:mm:ss').add(4, 'hours').format('YYYY/MM/DD HH:mm:ss');

const fecha1 = moment(fechaInicio, 'YYYY/MM/DD HH:mm:ss').add(4, 'hours').format('YYYY-MM-DD HH-mm'); 
const fecha2 = moment(fechaFin, 'YYYY/MM/DD HH:mm:ss').add(4, 'hours').format('YYYY-MM-DD HH-mm');

const [enviado, setEnviado] = useState(false);

const enviar = () => {
  if (fechai > fechaf) {
    Swal.fire({
      title: "La fecha de inicio no puede ser mayor a la fecha fin",
      confirmButtonText: 'OK',
      icon: "warning",
    });
  }
  if (fechai === "Invalid date" || fechaf === "Invalid date") {
    Swal.fire({
      title: "Seleccione un rango de fechas valido",
      confirmButtonText: 'OK',
      icon: "warning",
    });
  } else {
    if (fechai !== "Invalid date" && fechaf !== "Invalid date") {
      setEnviado(true);
  }
}
}
const [renderexcel, setRenderexcel] = useState(false); // Variable de estado para controlar si el response viene en blanco
const handleDownload = async () => {
  setRenderexcel(true); // Cambiar renderexcel a true antes de la petición fetch

  const data = {
    "equipo": selectedEquipo,
    "dateinicio": fechai,
    "datefin": fechaf
  };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  const response = await fetch('https://www.mine-360.com/api/cabina/sensor/reporteScabina', requestOptions);
  
  if (response.status === 500) { 
    Swal.fire({
    title: "Sin datos en el rango de fechas seleccionado.",
    confirmButtonText: 'OK',
    icon: "error",
  }); return; }

  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  // Cambia el nombre del archivo aquí
  const fileName = equipoexcel + " desde " + fecha1 + " hasta " + fecha2 + ".xlsx";
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  setRenderexcel(false);
}

// FIN MODO MANUAL

return (

<div className="cabina">

  <Banner/>
  <Sidebar/>

  <div className="cabina-body">

    <div className="division1">

      <div className="division1-1">

        <label><input type="radio" value="option1" checked={selectedOption === 'option1'} onChange={() => handleChange('option1')} /> MATERIAL PARTICULADO</label>
        <label><input type="radio" value="option2" checked={selectedOption === 'option2'} onChange={() => handleChange('option2')} /> GASES</label>
        <label><input type="radio" value="option3" checked={selectedOption === 'option3'} onChange={() => handleChange('option3')} /> AMBIENTE</label>

      </div>

      <div className="division1-2">

      <div className={(selectedOption === "option1" || selectedOption === "option2" || selectedOption === "option3") && selectedOption2 === "opcion 0" ? "division1-2-1-2" : "division1-2-1"}>

          <div class="menu">
          <select  onChange={handleChange2}>
            <option value="opcion 0"  defaultValue hidden>SELECCIONE EQUIPO</option>
            <option value="opcion 1" >PERFORADORA 1</option>
            <option value="opcion 2" >PERFORADORA 2</option>
            <option value="opcion 3" disabled hidden>PERFORADORA 3</option>
            <option value="opcion 4" >PERFORADORA 4</option>
            <option value="opcion 5" >PERFORADORA 5</option>
            <option value="opcion 6" disabled hidden>CAEX 1</option>
            <option value="opcion 7" disabled hidden>CAEX 2</option>
            <option value="opcion 8">CAEX 3</option>
            <option value="opcion 9" >CAEX 4</option>
            <option value="opcion 15" >CAEX 5</option>
            <option value="opcion 14">CAEX 13</option>
            <option value="opcion 11">CAEX 21</option>
            <option value="opcion 10">CAEX 23</option>
            <option value="opcion 13">CAEX 28</option>
            <option value="opcion 12">CAEX 29</option>
          </select>
          </div>

        </div>

        <div className="division1-2-2">

        <div>Fecha: {formatDate(date)}</div>
        <div>Hora: {formatTime(date)}</div>

        </div>

        <div className="division1-2-3">

        <img src={cerrar} alt="regresar" height="40px" width="40px" onClick={Home}></img>

        </div>



      </div>

    </div>

    <div className="division2">

    <div className="division2-1">

      

      {selectedOption === 'option1' && <div className="division2-1">

          <div className="estilotitulo1">MATERIAL PARTICULADO [µg/m³]</div>

          <div className="boxes">

            <div className="box1">
              
              <div className="estilotitulo2">PM 1</div>

                {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
                {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPM1(equipo.PPM1).backgroundColor, color: getColorPM1(equipo.PPM1).color}} className="box2">{equipo.PPM1 !== null && equipo.PPM1 !== undefined ? equipo.PPM1 : "SIN DATOS"}</div></div>}

            </div>

            <div className="box1">
              
              <div className="estilotitulo2">PM 2.5</div>

                {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
                {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPM25(equipo.PPM25).backgroundColor, color: getColorPM25(equipo.PPM25).color}} className="box2">{equipo.PPM25 !== null && equipo.PPM25 !== undefined ? equipo.PPM25 : "SIN DATOS"}</div></div>}

            </div>

            <div className="box1">
              
              <div className="estilotitulo2">PM 10</div>
              
                {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black" }} className="box2">SELECCIONE EQUIPO</div></div>}
                {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPM10(equipo.PPM10).backgroundColor, color: getColorPM10(equipo.PPM10).color}} className="box2">{equipo.PPM10 !== null && equipo.PPM10 !== undefined ? equipo.PPM10 : "SIN DATOS"}</div></div>}
            
            </div>


          </div>
        
        </div>}

      {selectedOption === 'option2' && <div className="division2-1">

        <div className="estilotitulo1">GASES</div>

        <div className="boxes3">

          <div className="box4">
            
            <div className="estilotitulo4">{"CO2 (ppm)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}}  className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPCO2(equipo.PCO2).backgroundColor, color: getColorPCO2(equipo.PCO2).color}} className="box5">{equipo.PCO2 !== null && equipo.PCO2 !== undefined ? equipo.PCO2 : "SIN DATOS"}</div></div>}

          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"VOC"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPVOC(equipo.PVOC).backgroundColor, color: getColorPVOC(equipo.PVOC).color}} className="box5">{equipo.PVOC !== null && equipo.PVOC !== undefined ? equipo.PVOC : "SIN DATOS"}</div></div>}
            
          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"CH2O (mg/m³)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPCH2O(equipo.PCH2O).backgroundColor, color: getColorPCH2O(equipo.PCH2O).color}} className="box5">{equipo.PCH2O !== null && equipo.PCH2O !== undefined ? equipo.PCH2O : "SIN DATOS"}</div></div>}

          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"CO (ppm)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPCO(equipo.PCO).backgroundColor, color: getColorPCO(equipo.PCO).color}} className="box5">{equipo.PCO !== null && equipo.PCO !== undefined ? equipo.PCO : "SIN DATOS"}</div></div>}

          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"O3 (ppm)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPO3(equipo.PO3).backgroundColor, color: getColorPO3(equipo.PO3).color}} className="box5">{equipo.PO3 !== null && equipo.PO3 !== undefined ? equipo.PO3 : "SIN DATOS"}</div></div>}

          </div>

          <div className="box4">
            
            <div className="estilotitulo4">{"NO2 (ppm)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}}  className="box5">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPNO2(equipo.PNO2).backgroundColor, color: getColorPNO2(equipo.PNO2).color}} className="box5">{equipo.PNO2 !== null && equipo.PNO2 !== undefined ? equipo.PNO2 : "SIN DATOS"}</div></div>}

          </div>

          </div>
      
      </div>}

      {selectedOption === 'option3' && <div className="division2-1">

      <div className="estilotitulo1">AMBIENTE</div>

          <div className="boxes">

            <div className="box1">
              
              <div className="estilotitulo2">{"TEMPERATURA (°C)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{backgroundColor: getColorPTEMP(equipo.PTEMP).backgroundColor, color: getColorPTEMP(equipo.PTEMP).color}} className="box2">{equipo.PTEMP !== null && equipo.PTEMP !== undefined ? equipo.PTEMP : "SIN DATOS"}</div></div>}

            </div>

            <div className="box1">
              
              <div className="estilotitulo2">{"HUMEDAD (%RH)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPHUM(equipo.PHUM).backgroundColor, color: getColorPHUM(equipo.PHUM).color}} className="box2">{equipo.PHUM !== null && equipo.PHUM !== undefined ? equipo.PHUM : "SIN DATOS"}</div></div>}
              
            </div>

            <div className="box1">
              
              <div className="estilotitulo2">{"RUIDO (dBA)"}</div>
              {selectedOption2 === 'opcion 0' && <div><div style={{ backgroundColor: "white", color: "black"}} className="box2">SELECCIONE EQUIPO</div></div>}
              {selectedOption2 !== 'opcion 0' && <div><div style={{ backgroundColor: getColorPRUIDO(equipo.PRUIDO).backgroundColor, color: getColorPRUIDO(equipo.PRUIDO).color}} className="box2">{equipo.PRUIDO !== null && equipo.PRUIDO !== undefined ? equipo.PRUIDO : "SIN DATOS"}</div></div>}
              
            </div>

          </div>
      
      </div>}

    </div>

    <div className="division2-2">

    {selectedOption === 'option1' && <div className="division2-2">

          <div className="boxes2">

            <div className="box3">
              
              <div className="estilotitulo3">NIVEL</div>
              <div className="estilotitulo3" style={{ backgroundColor: "green", color: "white" }}>BAJO</div>
              <div className="estilotitulo3" style={{ backgroundColor: "yellow", color: "black" }}>MEDIO</div>
              <div className="estilotitulo3" style={{ backgroundColor: "red", color: "white" }}>ALTO</div>

            </div>

            <div className="box3">
              
              <div className="estilotitulo2">PM 1</div>
              <div className="estilotitulo2">{"< 10"}</div>
              <div className="estilotitulo2">{"10 ≤ X ≤ 25"}</div>
              <div className="estilotitulo2">{"> 25"}</div>
              
            </div>

            <div className="box3">
              
              <div className="estilotitulo2">PM 2.5</div>
              <div className="estilotitulo2">{"< 25"}</div>
              <div className="estilotitulo2">{"25 ≤ X ≤ 50"}</div>
              <div className="estilotitulo2">{"> 50"}</div>

            </div>

            <div className="box3">
              
              <div className="estilotitulo2">PM 10</div>
              <div className="estilotitulo2">{"< 50"}</div>
              <div className="estilotitulo2">{"50 ≤ X ≤ 100"}</div>
              <div className="estilotitulo2">{"> 100"}</div>

            </div>

          </div>
        
        </div>}

      {selectedOption === 'option2' && <div className="division2-1">
      
      <div className="boxes2">

      <div className="box3">
        
        <div className="estilotitulo3">NIVEL</div>
        <div className="estilotitulo3" style={{ backgroundColor: "green", color: "white" }}>BAJO</div>
        <div className="estilotitulo3" style={{ backgroundColor: "yellow", color: "black" }}>MEDIO</div>
        <div className="estilotitulo3" style={{ backgroundColor: "red", color: "white" }}>ALTO</div>

      </div>

      <div className="box3-1">

        <div className="estilotitulo2-1">CO2 </div>
        <div className="estilotitulo2-1">{"< 500"}</div>
        <div className="estilotitulo2-1">{"500 ≤ X ≤ 700"}</div>
        <div className="estilotitulo2-1">{"> 700"}</div>
        
      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">VOC</div>
        <div className="estilotitulo2-1">{"< 3.5"}</div>
        <div className="estilotitulo2-1">{"3.5 ≤ X ≤ 6"}</div>
        <div className="estilotitulo2-1">{"> 6"}</div>


      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">CH2O</div>
        <div className="estilotitulo2-1">{"< 1"}</div>
        <div className="estilotitulo2-1">{"1 ≤ X ≤ 3"}</div>
        <div className="estilotitulo2-1">{"> 3"}</div>

      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">CO</div>
        <div className="estilotitulo2-1">{"< 0.6"}</div>
        <div className="estilotitulo2-1">{"0.6 ≤ X ≤ 1"}</div>
        <div className="estilotitulo2-1">{"> 1"}</div>

      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">O3</div>
        <div className="estilotitulo2-1">{"< 0.1"}</div>
        <div className="estilotitulo2-1">{"0.1 ≤ X ≤ 0.2"}</div>
        <div className="estilotitulo2-1">{"> 0.2"}</div>

      </div>

      <div className="box3-1">
        
        <div className="estilotitulo2-1">NO2</div>
        <div className="estilotitulo2-1">{"< 0.15"}</div>
        <div className="estilotitulo2-1">{"0.15 ≤ X ≤ 2"}</div>
        <div className="estilotitulo2-1">{"> 2"}</div>

      </div>

      </div>

      </div>}

      {selectedOption === 'option3' && <div className="division2-1">
        
      <div className="boxes2">

        <div className="box3">
          
          <div className="estilotitulo3">NIVEL</div>
          <div className="estilotitulo3" style={{ backgroundColor: "green", color: "white" }}>BAJO</div>
          <div className="estilotitulo3" style={{ backgroundColor: "yellow", color: "black" }}>MEDIO</div>
          <div className="estilotitulo3" style={{ backgroundColor: "red", color: "white" }}>ALTO</div>

        </div>

        <div className="box3">
          
          <div className="estilotitulo2">TEMPERATURA</div>
          <div className="estilotitulo2">{"< 25"}</div>
          <div className="estilotitulo2">{"25 ≤ X ≤ 35"}</div>
          <div className="estilotitulo2">{"> 35"}</div>
          
        </div>

        <div className="box3">
          
          <div className="estilotitulo2">HUMEDAD</div>
          <div className="estilotitulo2">{"< 50"}</div>
          <div className="estilotitulo2">{"50 ≤ X ≤ 75"}</div>
          <div className="estilotitulo2">{"> 75"}</div>

        </div>

        <div className="box3">
          
          <div className="estilotitulo2">RUIDO</div>
          <div className="estilotitulo2">{"< 55"}</div>
          <div className="estilotitulo2">{"55 ≤ X ≤ 80"}</div>
          <div className="estilotitulo2">{"> 80"}</div>

        </div>

        </div>

        </div>}

    </div>

    </div>

    <div className="division3">

    {(selectedOption === "option1" || selectedOption === "option2" || selectedOption === "option3") && selectedOption2 !== 'opcion 0' &&<div className="division3-0">
          <select className="selectgraph" onChange={handleChange3}> 
            <option value="op1">Tiempo Real</option>
            <option value="op2">Histórico</option>
          </select>

          {selectedOption3 === 'op2' && <input className="selectgraphbutons" type="datetime-local" step="1" name="fechainicio" onChange={handleFechaInicioChange}></input>}
          {selectedOption3 === 'op2' && <input className="selectgraphbutons" type="datetime-local" step="1" name="fechafin" onChange={handleFechaFinChange}></input>}
          {selectedOption3 === 'op2' && <button type="button" className="botoncabina" onClick={enviar} >Enviar</button>}
          {selectedOption3 === 'op2' && enviado && !renderexcel &&<img src={excel} alt="boton excel" onClick={handleDownload} style={{ height:"32px", borderRadius: "100px"}} type="button"></img>}
          {selectedOption3 === 'op2' && enviado && renderexcel && <CirclesToRhombusesSpinner/>}
    </div>}

    {selectedOption2 === 'opcion 0' && <div className="division3-1"></div>}

    {selectedOption2 !== 'opcion 0' && selectedOption === 'option1' && selectedOption3 === 'op1' &&<div className="division3-1"><MP equipo={selectedEquipo} tag={equipoexcel}/></div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option1' && selectedOption3 === 'op2' &&<div className="division3-1">{enviado && <MPM equipo={selectedEquipo} tag={equipoexcel} fechainicio={fechai} fechafin={fechaf}/>}</div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option2' && selectedOption3 === 'op1' &&<div className="division3-1"><G equipo={selectedEquipo} tag={equipoexcel}/></div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option2' && selectedOption3 === 'op2' &&<div className="division3-1">{enviado && <GM equipo={selectedEquipo} tag={equipoexcel} fechainicio={fechai} fechafin={fechaf}/>}</div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option3' && selectedOption3 === 'op1' &&<div className="division3-1"><A equipo={selectedEquipo} tag={equipoexcel}/></div>}
    {selectedOption2 !== 'opcion 0' && selectedOption === 'option3' && selectedOption3 === 'op2' &&<div className="division3-1">{enviado && <AM equipo={selectedEquipo} tag={equipoexcel} fechainicio={fechai} fechafin={fechaf}/>}</div>}

    </div>

  </div>

</div>

    );
}

export default Cabina;
import React, { useState } from "react";
import logologin from "./Imagenes/logologin.png"; // Ruta de la imagen
import "./loginform.css";



const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  //const history = useHistory();
  
/*
  const handleSubmit = (event) => {
    event.preventDefault();

    // Realizar la solicitud de inicio de sesión al backend en localhost:4000/api/signin
    fetch("http://localhost:4000/api/auth/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => {
        // Obtener el valor del encabezado "auth-token"
        const authToken = response.headers.get("auth-token");
        localStorage.setItem("authToken", authToken); // Almacenar el token en el almacenamiento local
        return response.json();
      })
      /*.then((data) => {
        // Manejar la respuesta del backend
        console.log(data);
        // Redireccionar al usuario a la pantalla de inicio del sistema
        window.location.href = "/index"; // Cambiar "/index" por la ruta correcta de la pantalla de inicio del sistema
      })*/
      
     /*
      .catch((error) => {
        console.error(error);
      });
  };
*/

const handleSubmit = async (event) => {
  event.preventDefault();

  try {
    const response = await fetch("https://www.mine-360.com/api/auth/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ username, password })
    });

    if (response.status === 200) {
      
      const authToken = response.headers.get("auth-token");
      localStorage.setItem("authToken", authToken);
      localStorage.setItem('username', username);

      const data = await response.json();
      sessionStorage.setItem('rol', data.role);
      localStorage.setItem('firstlogin', data.firstlogin);

      const firstime = localStorage.getItem("firstlogin");

      if (firstime === 'true') {
        window.location.href = "/firstlogin";
      } else {
        window.location.href = "/inicio";
      }

    } else if (response.status === 400) {
      setError("Usuario inválido");
    } else if (response.status === 401) {
      setError("Contraseña inválida");
    } else {
      setError("Error desconocido");
    }
  } catch (error) {
    setError("Error de conexión");
  }
};

  return (
    
    <div>
<section className="vh-100 gradient-custom">
      <div className="container py-2 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-14 col-md-8 col-lg-6 col-xl-5">
            <div className="bg-dark text-white">
              <div className="card-body px-5 py-0 text-center">
                
              {error && <div className="alert alert-danger">{error}</div>} 
              <form onSubmit={handleSubmit}>
                <div className="mb-md-5 mt-md-4 pb-5">

              
                    {/* Utiliza la imagen como origen de img */}
                    <img src={logologin}  style={{width: '150px' }} alt="Logo" />
                

                  <p className="text-white-50 mb-3">Por favor ingresa tus datos</p>
                  <div className="form-outline form-white mb-2">
                  <label className="form-label" htmlFor="typeEmailX">Rut o nombre de usuario</label>
                 
                    <input
              type="text"
              className="form-control form-control-lg"
              id="username"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
                    
                  </div>
                  <div className="form-outline form-white mb-4">
                  <label className="form-label" htmlFor="password">Contraseña</label>
                
                    
                    <input
              type="password"
              className="form-control form-control-lg"
              id="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />


                  </div>
                  <p className="small mb-5 pb-lg-2"><a className="text-white-50" href="#!">Olvidó su contraseña?</a></p>
                  <button className="btn btn-outline-light btn-lg px-5" type="submit">Iniciar sesión</button>
                 
                
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>

);
};
export default LoginForm;


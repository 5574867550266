import React from 'react';
import './listado.css';
import Banner from './Componentes/banner'
import SearchComponent from './Componentes/SearchComponent'
import Footer2 from './Componentes/footer2';
import cerrar from './Imagenes/cerrar.png'
import Sidebar from './Componentes/sidebar'

const Listado = () => {

  const Home = () => {

    window.location.href = "/inicio";
  
  };

return (

<div className="listado">

  <Banner/>
  <Sidebar/>


  <div className="listado-body">

    <img src={cerrar} alt="regresar" style={{ height:"40px", width:"40px", position:"absolute", right:"15px", top:"9vh", zIndex:"2", cursor:"pointer"}} onClick={Home}></img>

    <div className="listadocontainer">


    <SearchComponent/>
    
    </div>

  </div>

  <Footer2/>

</div>
  );
}

export default Listado;
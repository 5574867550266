
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import './formulario.css';
import Banner from './Componentes/banner'
import cerrar from './Imagenes/cerrar.png'
import Sidebar from './Componentes/sidebar'

const Nuevochip = () => {

  const [formData, setFormData] = useState({
    numero_telefono: '',
    estado: '2',
    empresa_chip: '',
    imei: ''
    })

  const Home = () => {

    window.location.href = "/inicio";
  
  };
 
  const [empresa, setempresa] = useState([]);
    useEffect(() => {
      fetch('http://18.207.128.95:8080/api/ficha/getempresachip')
        .then(response => response.json())
          .then(data => {
            setempresa(data);
          })
          .catch(error => {
            console.error('Error al obtener los responsables de la API:', error);
          });
    }, []);

    const [estadoequipo, setestadoequipo] = useState([]);
    useEffect(() => {
      fetch('http://18.207.128.95:8080/api/ficha/getestadochips')
        .then(response => response.json())
          .then(data => {
            setestadoequipo(data);
          })
        .catch(error => {
          console.error('Error al obtener los responsables de la API:', error);
        });
    }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
  };

  const resetForm = () => {
    setFormData({
      numero_telefono: '',
      imei: '',
      estado: formData.estado,
      empresa_chip: formData.empresa_chip
    });
    setValue('');
    setValue2('');
  };

  const handleSubmit = async (e) => {
    try {
      const response = await fetch('http://18.207.128.95:8080/api/ficha/chipin', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
      });
      
      if (response.status === 411 || response.status === 412) {
        Swal.fire({
          title: "N° de Teléfono/Imei ya se encuentra en uso",
          confirmButtonText: 'Atras',
          icon: "error",
        })
        console.error('Error en la solicitud');
      } else if (response.ok) {
        Swal.fire({
          title: "Guardado!",
          confirmButtonText: 'OK',
          icon: "success",
        })
        resetForm();
      } else {
        Swal.fire({
          title: "Error al enviar los datos!",
          confirmButtonText: 'Atras',
          icon: "error",
        })
        console.error('Error al enviar los datos');
      }
    } catch (error) {
      console.error('Error al enviar los datos: ', error);
      Swal.fire({
        title: "Error al enviar los datos!",
        confirmButtonText: 'Atras',
        icon: "error",
      })
    }
  };


  const enviar = (e) => {
    e.preventDefault();
      if ( formData.estado  === '' || formData.empresa_chip  === ''){
        Swal.fire({
          title: "Rellene los campos faltantes!",
          confirmButtonText: 'OK',
          icon: "error",
        })
      }else{
      Swal.fire({
        title: "Quieres guardar los cambios?",
        showCancelButton: true,
        confirmButtonText: "Guardar", 
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit();
        }
      });  
      } 
  }

  const [valuenro, setValue] = useState('');
    const handleChange2 = (e) => {
    const newValue = e.target.value.replace(/[^\d]/g, ''); // Remueve todos los caracteres que no sean dígitos
    setValue(newValue);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [valuenro2, setValue2] = useState('');
  const handleChange3 = (e) => {
    const newValue2 = e.target.value.replace(/[^\d]/g, ''); // Remueve todos los caracteres que no sean dígitos
    setValue2(newValue2);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

return (

<div className="nuevoequipo">

    <Banner/>
    <Sidebar/>

    <div className="nuevoequipo-body">

    <img src={cerrar} alt="regresar" style={{ height:"40px", width:"40px", position:"absolute", right:"5px", top:"9vh", zIndex:"2", cursor:"pointer"}} onClick={Home}></img>

        <div id="formularionuevoequipo" className="formularionuevoequipo">

            <div className="formularionuevoequipo-1">

            <div className="ordenar" >
            <label>EMPRESA:</label>
              <select className="estilo" name="empresa_chip" onChange={handleChange}>
                <option value={formData.empresa_chip} disabled defaultValue hidden>Seleccionar</option>
                {empresa.map(empresa => (
                <option key={empresa.id_empresa} value={empresa.idempresa_chip}>{empresa.nombre}</option>))}
              </select>
            </div>

                <div className="ordenar">
                    <label for="empresa">N° IDENTIFICADOR DE CHIP:</label>
                    <input name="numero_telefono" value={valuenro} className="estilo" type="text" onChange={handleChange2}/>
                </div>

                <div className="ordenar">
                    <label for="empresa">N° DE TELEFONO DEL CHIP:</label>
                    <input name="imei" value={valuenro2} className="estilo" type="text" onChange={handleChange3}/>
                </div>

                <div className="ordenar" >
                <label>ESTADO DEL EQUIPO:</label>
                <select className="estilo" name="estado" onChange={handleChange}>
                <option value={formData.estadoequipo} disabled defaultValue hidden>ACTIVO DESINSTALADO</option>
                {estadoequipo.map(estadoequipo => (
                <option disabled key={estadoequipo.id_estadoequipo} value={estadoequipo.idestado}>{estadoequipo.tipoestado}</option>))}
                </select>
                </div>

            </div>

            <div className="formularionuevoequipo-2">

                <button type="Submit" className="boton" onClick={Home}>Atras</button>
                <button type="Submit" className="boton" onClick={enviar}>Enviar</button>

            </div>

        </div>
     
    </div>

</div>
  );
}

export default Nuevochip;
import React, { useState, useEffect } from 'react';
import './riego.css';
import Banner from './Componentes/banner'
import Thermometer from 'react-thermometer-ecotropy'
import flecha from './Imagenes/flecha.png'
import sol from './Imagenes/sol0.png'
import sol1 from './Imagenes/sol1.png'
import sol2 from './Imagenes/sol2.png'
import sol3 from './Imagenes/sol3.png'
import sol4 from './Imagenes/sol4.png'
import ReactSpeedometer from "react-d3-speedometer"
import Tank from './Componentes/Tank'
import SearchComponent3 from './Componentes/SearchComponent3'
import Sidebar from './Componentes/sidebar'
import WLH from './Graficos/WLH'
import Swal from 'sweetalert2'
import panicon from './Imagenes/panicon.png'
import panicoff from './Imagenes/panicoff.png'
import regon1 from './Imagenes/regon1.png'
import regon2 from './Imagenes/regon2.png'
import regon3 from './Imagenes/regon3.png'
import regoff from './Imagenes/regoff.png'
import cctv from './Imagenes/cctv.png'


function Riego() {

  const home = () => {

    window.location.href = "/inicio";
  
  };
  
  function PanicJson(data) {
    const xhr = new XMLHttpRequest();
    const url = "https://www.mine-360.com/api/app/setmodo";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
  
    xhr.onload = function () {
      var status = xhr.status;
      var response = xhr.responseText;
      console.log("Código de estado: " + status);
      console.log("Contenido: " + response);
    };
  
    xhr.send(data);
  
  }
  
  
  const enviar = (e) => {
    e.preventDefault();
    if (estatuspanic.Panic === 1) {
      Swal.fire({
        title: "Esta a punto de detener el sistema",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          Panic();
        }
      });
    } else {
      Swal.fire({
        title: "Desea iniciar el sistema?",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: "Iniciar",
        icon: "info",
      }).then((result) => {
        if (result.isConfirmed) {
          Panic();
        }
      });
    }
  }
  
  
  const Panic = () => {
   const username = localStorage.getItem('username')
  
   const apaga = JSON.stringify({
    "sistema":0,
    "username": username
  });
  
  const enciende = JSON.stringify({
    "sistema":1,
    "username": username
  });
  
  estatuspanic.Panic ? PanicJson(apaga) : PanicJson(enciende)
  
  };

  const [estatusSemaf, setEstatusSemaf] = useState({});
  const url_base = 'https://www.mine-360.com/api/app/status_aspersores';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url_base);
        const data = await response.json();

        setEstatusSemaf({
          sem1: data[0],
          sem2: data[1],
          sem3: data[2],
          sem4: data[3],
          sem5: data[4],
          sem6: data[5],
          sem7: data[6],
          sem8: data[7],
          sem9: data[8],
          sem10: data[9],
          sem11: data[10],
          sem12: data[11],
          sem13: data[12],
          sem14: data[13],
          sem15: data[14],
          sem16: data[15],
          sem17: data[16],
          sem18: data[17],
          sem19: data[18],
          sem20: data[19],
          sem21: data[20],
          sem22: data[21],
          sem23: data[22],
          sem24: data[23],
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const aspersoresonline = Object.values(estatusSemaf).reduce((count, item) => {
    if (item === true) {
      count++;
    }
    return count;
  }, 0);

  const [estatuspanic, setEstatusPanic] = useState({});
  const url_base2 = 'https://www.mine-360.com/api/app/getmodo';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url_base2);
        const data = await response.json();

        setEstatusPanic({
          Panic: data.sistema,
    
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    const interval2 = setInterval(fetchData, 3000);

    return () => {
      clearInterval(interval2);
    };
  }, []);

const [estatusflujo, setEstatusflujo] = useState({});
const [altura, setAltura] = useState(0);
const [volumen, setVolumen] = useState(0);
const [porcentaje, setPorcentaje] = useState(0);

const url_base3 = 'https://www.mine-360.com/api/app/getvariables';

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(url_base3);
      const data = await response.json();

      setEstatusflujo({
        Presion: data.flujo_presion,
        Medida: data.medida_vol,
      });

      const alturaCalculada = (4.10 - data.medida_vol).toFixed(1);
      const volumenCalculado = (2 * ((6 / 2) ** 2) * Math.PI * alturaCalculada).toFixed(0);
      const porcentajeCalculado = (alturaCalculada / 4.10) * 100;

      setAltura(alturaCalculada);
      setVolumen(volumenCalculado);
      setPorcentaje(porcentajeCalculado);

    } catch (error) {
      console.error(error);
    }
  };

  setAltura(0);
  setVolumen(0);
  setPorcentaje(0);

  fetchData();

  const interval = setInterval(fetchData, 60000);

  return () => {
    clearInterval(interval);
  };
}, []);


  const [meteorologico, setEstatusmeteoro] = useState({});
  const url_base4 = 'https://rt.ambientweather.net/v1/devices?applicationKey=4da04f81343340fc8fd2bb98f4c738a138b5d81bb08f471fbc235226f872356c&apiKey=e0b481c9c80045b89872476f987c08221705a306690d4aaebe338d8098909aa4';

useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(url_base4);
          const data = await response.json();
  
          setEstatusmeteoro({
            nombre: data[0].info.coords.address,
            winddir: data[0].lastData.winddir,
            tempf: ((data[0].lastData.tempf - 32 )* 5/9).toFixed(2),
            solarradiation: data[0].lastData.solarradiation.toFixed(),
            humidity: data[0].lastData.humidity,
            windspeedmph: (data[0].lastData.windspeedmph*0.44704).toFixed(2)
            
          });
        } catch (error) {
          console.error(error);
        }
      };
      
      fetchData();
      
      const interval4 = setInterval(fetchData, 5000);
      
      return () => {
        clearInterval(interval4);
      };
    }, []);

    const [estatusvolumen, setEstatusvolumen] = useState({});
    const url_base5 = 'https://www.mine-360.com/api/app/getc1dep0';
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(url_base5);
          const data = await response.json();
    
          setEstatusvolumen({
            mes: data.volumenMes,
            dia: data.volumenDia,
            hora: data.volumenHora,
      
          });
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchData();
    
      const interval5 = setInterval(fetchData, 60000);
    
      return () => {
        clearInterval(interval5);
      };
    }, []);

    const [estatusSensores, setEstatusSensores] = useState({});
    const url_base6 = 'https://www.mine-360.com/api/app/getult_sval';
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(url_base6);
          const data = await response.json();
    
            const maquina1Sensor1 = data.find(item => item.equipo === 2)?.sensores[0];
            const maquina1Sensor2 = data.find(item => item.equipo === 2)?.sensores[1];
            const maquina2Sensor1 = data.find(item => item.equipo === 3)?.sensores[0];
            const maquina2Sensor2 = data.find(item => item.equipo === 3)?.sensores[1];
      
            setEstatusSensores({ maquina1Sensor1, maquina1Sensor2, maquina2Sensor1, maquina2Sensor2 });
      
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchData();
    
      const interval6 = setInterval(fetchData, 1000);
    
      return () => {
        clearInterval(interval6);
      };
    }, []);

    const [showTooltip1, setShowTooltip1] = useState(false);
const [showTooltip2, setShowTooltip2] = useState(false);
const [showTooltip3, setShowTooltip3] = useState(false);
const [showTooltip4, setShowTooltip4] = useState(false);

  const handleMouseEnter1 = () => {
    setShowTooltip1(true);
  }
  const handleMouseLeave1 = () => {
    setShowTooltip1(false);
  }
  const handleMouseEnter2 = () => {
    setShowTooltip2(true);
  }
  const handleMouseLeave2 = () => {
    setShowTooltip2(false);
  }
  const handleMouseEnter3 = () => {
    setShowTooltip3(true);
  }
  const handleMouseLeave3 = () => {
    setShowTooltip3(false);
  }
  const handleMouseEnter4 = () => {
    setShowTooltip4(true);
  }
  const handleMouseLeave4 = () => {
    setShowTooltip4(false);
  }


    const estiloFlecha = {
      width: '40px',  // Establece el tamaño de la imagen de la flecha
      transform: `rotate(${(meteorologico.winddir+170)}deg)`  // Rota la imagen según los grados de winddir
    };
  


    const obtenerImagenSol = (solarradiation) => { 
      if (solarradiation >= 0 && solarradiation < 10) { return sol; } 
      else if (solarradiation >= 10 && solarradiation < 40) { return sol1; } 
      else if (solarradiation >= 40 && solarradiation < 100) { return sol2; } 
      else if (solarradiation >= 100 && solarradiation < 150) { return sol3; } 
      else { return sol4; } };

      const listadohistorico = () => {
        const element = document.getElementById("listadohistorico");
        if (element.style.display === "flex") {
          element.style.bottom = "-650px";
          setTimeout(() => {
            element.style.display = "none";
          }, 500); // Espera a que termine la animación para ocultar completamente el elemento
        } else {
          element.style.bottom = "0px";
          element.style.display = "flex";
        }
    }

    const [imagenIndex, setImagenIndex] = useState(0);
    const imagenes = [regon1, regon2, regon3];

    useEffect(() => {
      const timer = setTimeout(() => {
        setImagenIndex((imagenIndex + 1) % imagenes.length);
      }, 300);

      return () => clearTimeout(timer);
    }, [imagenIndex]);
      

    const [imagenAmpliada, setImagenAmpliada] = useState(null);

    const handleClick = (numero) => {
      const nuevaImagen = `/imagenes/riegocam0${numero}.jpg?timestamp=${new Date().getTime()}`;
      setImagenAmpliada(nuevaImagen);
    }


return (

    <div className="Riego">
      
      <Banner/>
      <Sidebar/>

      {imagenAmpliada && (
        <div className="imagenAmpliada" onClick={() => setImagenAmpliada(null)}>
          <img alt="consola" src={imagenAmpliada}></img>
        </div>

)}

      <div id="cerrarDiv" onClick={home} style={{position: "absolute", top: "8vh", right: "5px", padding: "5px", cursor: "pointer", fontSize: "24px", fontWeight: "bold", zIndex: "2" }}>X</div>

      <div className="Riegotop">

      <div className="Riegotopitemcel">
          
          <div style={{ height: "14vh", width: "270px", display: "grid", justifyContent: "center", alignItems: "center" }}>
           
            <div style={{ display: "flex", height: "3vh", fontSize: "14px", justifyContent: "center", fontWeight: "bold", textAlign: "center" }}>{(meteorologico.nombre)}</div>

          </div>

      </div>

        <div className="Riegotopitem">
          <div style={{ height: "14vh", width: "100px", display: "grid", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "10vh", width: "100px", display: "grid", justifyContent: "center", alignContent: "center" }}>
            <Thermometer
            theme="dark"
            value={(meteorologico.tempf)}
            max="100"
            format="°C"
            size="small"
            height="80"
            tooltipValue={false}
            />
            </div>
            <div style={{ display: "flex", height: "3vh", fontSize: "20px", justifyContent: "center", fontWeight: "bold"}}>{(meteorologico.tempf)} °C</div>
          </div>

          <div style={{ height: "13vh", width: "170px", display: "grid"}}>
          <div style={{ height: "2vh", width: "170px", fontSize: "16px", textAlign: "center"}}>Temperatura:</div>
          <div><WLH field="temp"/></div>
          </div>

        </div>

        <div className="Riegotopitem">

          <div style={{ height: "14vh", width: "100px", display: "grid", justifyContent: "center", alignItems: "center" }}>

            <div style={{ height: "10vh", width: "100px", display: "grid", justifyContent: "center", alignContent: "center" }}><img src={flecha} height="40px" alt="flecha" style={estiloFlecha}/></div>
            <div style={{ display: "flex", height: "3vh", fontSize: "20px", justifyContent: "center", fontWeight: "bold"}}>{meteorologico.winddir}°</div>

          </div>

          <div style={{ height: "13vh", width: "170px", display: "grid"}}>
            <div style={{ height: "2vh", width: "170px", fontSize: "16px", textAlign: "center"}}>Dirección del viento:</div>
            <div><WLH field="winddir"/></div>
          </div>

        </div>

        <div className="Riegotopitem">

        <div style={{ height: "14vh", width: "100px", display: "grid", justifyContent: "center", alignItems: "center"  }}>
          <div style={{ height: "10vh", width: "100px", display: "grid", justifyContent: "center", alignContent: "center" }}>
          <ReactSpeedometer
            maxValue={5}
            value={(meteorologico.windspeedmph)}
            needleColor="red"
            startColor="cyan"
            segments={5}
            endColor="blue"
            width={100}
            height={70}
            needleHeightRatio={0.5}
            valueTextFontSize="0px"
            labelFontSize="0px"
            ringWidth={15}
          />
          </div>
          <div style={{ display: "flex", height: "3vh", fontSize: "20px", justifyContent: "center", fontWeight: "bold"}}>{(meteorologico.windspeedmph)} m/s</div>
          </div>

          <div style={{ height: "13vh", width: "180px", display: "grid"}}>
            <div style={{ height: "2vh", width: "180px", fontSize: "16px", textAlign: "center"}}>Velocidad del viento:</div>
            <div><WLH field="windspeedms"/></div>
          </div>

        </div>

        <div className="Riegotopitem">

          <div style={{ height: "14vh", width: "100px", display: "grid", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "10vh", width: "100px", display: "grid", justifyContent: "center", alignContent: "center" }}><img height="60px" src={obtenerImagenSol(meteorologico.solarradiation)} alt="Sol"/></div>
            <div style={{ display: "flex", height: "3vh", fontSize: "20px", justifyContent: "center", fontWeight: "bold", whiteSpace: "nowrap"}}>{meteorologico.solarradiation} W/m²</div>
          </div>

          <div style={{ height: "13vh", width: "180px", display: "grid"}}>
            <div style={{ height: "2vh", width: "180px", fontSize: "16px", textAlign: "center"}}>Radiación solar:</div>
            <div><WLH field="solarradiation"/></div>
          </div>

        </div>

        <div className="Riegotopitem">

          <div style={{ height: "14vh", width: "100px", display: "grid", justifyContent: "center", alignItems: "center"}}>

          <div style={{ height: "10vh", width: "100px", display: "grid", justifyContent: "center", alignContent: "center" }}>

          <div style={{width: '10px', height: '60px', backgroundColor: 'grey',position: 'relative' }}>
            <div style={{ width: '10px', height: `${meteorologico.humidity}%`, backgroundColor: 'blue', position: 'absolute', bottom: 0}}/>
          </div>

          </div>
          
          <div style={{ display: "flex", height: "3vh", fontSize: "20px", justifyContent: "center", fontWeight: "bold"}}>{meteorologico.humidity} %RH</div>
          </div>

          <div style={{ height: "13vh", width: "180px", display: "grid"}}>
          <div style={{ height: "2vh", width: "180px", fontSize: "16px", textAlign: "center"}}>Humedad:</div>
          <div><WLH field="humidity"/></div>
          </div>

        </div>

        </div>

      <div className="Riegomid">
    
        <div className="Riegomidside">

        <div className="leyendatop">
        <div>Volumen hora:</div>
        <div className='leyendabold'>{estatusvolumen.hora} m³</div>

        <div>Volumen dia:</div>
        <div className='leyendabold'>{estatusvolumen.dia} m³</div>

        <div>Volumen mes:</div>
        <div className='leyendabold'>{estatusvolumen.mes} m³</div>

        </div>

        <div className="leyendamid">
        <div className='tank1'><Tank value={porcentaje} radius={65}/></div>
        <div className='tank2'><Tank value={porcentaje} radius={40}/></div>
        <div className='tank3'><Tank value={porcentaje} radius={25}/></div>
        </div>

        <div className="leyendabot">
        <div style={{  width: "10vw"}}>Altura actual:</div>
        <div className='leyendabold'>{altura} m</div>
        <div style={{  width: "10vw"}}> Volumen actual:</div>
        <div className='leyendabold'>{volumen} m³</div>
        </div>

        </div>

        <div className="Riegomidbody">
        
        <div className="Riegomidbodysem">

          <div className="Riegomidtop">
          
          <div className="semaforo">
                  {/* Maquina 1 sensor 1 */}
      {estatusSensores.maquina1Sensor1 === 1 ?
        <div style={{ position: "absolute", bottom: "-25px", left: "16px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}} onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}><div style={{borderRadius: "50%", backgroundColor: "red", width: "20px", height: "20px"}}> </div></div>
      : <div style={{ position: "absolute", bottom: "-25px", left: "16px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}} onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}><div style={{borderRadius: "50%", backgroundColor: "#94c120", width:"20px", height:"20px"}}> </div></div>}
      {showTooltip1 && (
        <div style={{position:"absolute", display:"grid", width:"170px", top:"50px", left:"50px", backgroundColor: "white", padding: "3px", zIndex: 99}}>
          <div style={{ display:"flex", alignItems: "center", width:"165px"}}>
            <div style={{backgroundColor: "red", border: "1px solid black", width:"20px", height:"20px"}}></div>
            <div style={{height:"20px", color:"black", textAlign:"left", marginLeft:"3px"}}>Camión en tránsito</div>
          </div>
          <div style={{ display:"flex", alignItems: "center", width:"165px", marginTop:"3px"}}>
            <div style={{backgroundColor: "#94c120", border: "1px solid black", width:"20px", height:"20px"}}></div>
            <div style={{width: "150px", height:"20px", color:"black", textAlign:"left", marginLeft:"3px"}}>Camino libre</div>
          </div>
      </div>
      )}
      {/* Maquina 1 sensor 1 */}
            <div className="regadortag">12A</div>
            {estatusSemaf.sem12 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px"}} ></img>}

          </div>  

          <div className="semaforo">
            <div className="regadortag">11A</div>
            {estatusSemaf.sem11 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px"}} ></img>}

          </div> 

          <div className="semaforo">
          <div className="regadortag">10A</div>
            {estatusSemaf.sem10 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px"}} ></img>}
          </div> 

          <div className="semaforo">
            <div className="regadortag">9A</div>

            {estatusSemaf.sem9 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px"}} ></img>}
          </div>

        <div className="semaforo">

        <div style={{ position: "absolute", bottom: "-25px", left: "86px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}}></div>
        <div style={{position:"absolute", display:"grid", width:"50px", top:"50px", left:"40px", padding: "3px", zIndex: 2}}>
        <img src={cctv} alt="camara1" onClick={() => handleClick(1)} style={{ height:"40px", width:"40px", transform: "rotateY(180deg)", cursor: "pointer"}} ></img>
        </div>

                {/* Maquina 1 sensor 2 .*/}
      {estatusSensores.maquina1Sensor2 === 1 ?
      <div style={{ position: "absolute", bottom: "-25px", left: "86px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}><div style={{borderRadius: "50%", backgroundColor: "red", width: "20px", height: "20px"}}> </div></div>
    : <div style={{ position: "absolute", bottom: "-25px", left: "86px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}><div style={{borderRadius: "50%", backgroundColor: "#94c120", width:"20px", height:"20px"}}> </div></div>}
      {showTooltip2 && (
        <div style={{position:"absolute", display:"grid", width:"170px", top:"50px", left:"120px", backgroundColor: "white", padding: "3px", zIndex: 99}}>
          <div style={{ display:"flex", alignItems: "center", width:"165px"}}>
            <div style={{backgroundColor: "red", border: "1px solid black", width:"20px", height:"20px"}}></div>
            <div style={{height:"20px", color:"black", textAlign:"left", marginLeft:"3px"}}>Camión en tránsito</div>
          </div>
          <div style={{ display:"flex", alignItems: "center", width:"165px", marginTop:"3px"}}>
            <div style={{backgroundColor: "#94c120", border: "1px solid black", width:"20px", height:"20px"}}></div>
            <div style={{width: "150px", height:"20px", color:"black", textAlign:"left", marginLeft:"3px"}}>Camino libre</div>
          </div>
      </div>
      )}
      {/* Maquina 1 sensor 2 */}
        <div className="regadortag" style={{position: "relative", right:"-70px"}}>8A</div>
        {estatusSemaf.sem8 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-70px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-70px"}} ></img>}
        </div> 

      <div className="semaforo">
        <div className="regadortag" style={{position: "relative", right:"-60px"}}>7A</div>
        {estatusSemaf.sem7 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-60px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-60px"}} ></img>}
      </div> 
    

      <div className="semaforo">
        <div className="regadortag" style={{position: "relative", right:"-50px"}}>6A</div>
        {estatusSemaf.sem6 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-50px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-50px"}} ></img>}
      </div>  

      <div className="semaforo">
        <div className="regadortag" style={{position: "relative", right:"-40px"}}>5A</div>
        {estatusSemaf.sem5 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-40px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-40px"}} ></img>}
      </div> 

      <div className="semaforo">
        <span className="regadortag" style={{position: "relative", right:"-30px"}}>4A</span>
        {estatusSemaf.sem4 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-30px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-30px"}} ></img>}
      </div> 

      <div className="semaforo">
        <div className="regadortag" style={{position: "relative", right:"-20px"}}>3A</div>
        {estatusSemaf.sem3 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-20px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-20px"}} ></img>}
      </div> 

      <div className="semaforo">
        <div className="regadortag" style={{ position: "relative", right:"-10px"}}>2A</div>
        {estatusSemaf.sem2 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-10px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", position: "relative", right:"-10px"}} ></img>}
      </div> 

      <div className="semaforo">
        <div className="regadortag">1A</div>
        {estatusSemaf.sem1 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px"}}></img> 
          : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px"}} ></img>}
      </div> 
          
      </div>

          <div className="Riegomidbot">

      <div className="semaforodos">
        {estatusSemaf.sem24 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}} ></img>}
        <div className="regadortag2" style={{ position: "relative", left:"-50px"}}>12B</div>
      </div> 

      <div className="semaforodos">
      {estatusSemaf.sem23 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-70px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-70px"}} ></img>}
        <div className="regadortag2" style={{position: "relative", left:"-70px"}}>11B</div>
      </div> 

      <div className="semaforodos">
      {estatusSemaf.sem22 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-90px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-90px"}} ></img>}
        <div className="regadortag2" style={{position: "relative", left:"-90px"}}>10B</div>
      </div>

      <div className="semaforodos">
      {estatusSemaf.sem21 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-110px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-110px"}} ></img>}
        <div className="regadortag2" style={{position: "relative", left:"-110px"}}>9B</div>
      </div>

      <div className="semaforodos">

{/* Maquina 2 sensor 1 */}
{estatusSensores.maquina2Sensor2 === 1 ?
<div style={{ position: "absolute", bottom: "105px", left: "-114px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}><div style={{borderRadius: "50%", backgroundColor: "red", width: "20px", height: "20px"}}> </div></div>
: <div style={{ position: "absolute", bottom: "105px", left: "-114px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}><div style={{borderRadius: "50%", backgroundColor: "#94c120", width:"20px", height:"20px"}}> </div></div>}
{showTooltip3 && (
<div style={{position:"absolute", display:"grid", width:"170px", top:"0px", left:"-80px", backgroundColor: "white", padding: "3px", zIndex: 99}}>
  <div style={{ display:"flex", alignItems: "center", width:"165px"}}>
    <div style={{backgroundColor: "red", border: "1px solid black", width:"20px", height:"20px"}}></div>
    <div style={{height:"20px", color:"black", textAlign:"left", marginLeft:"3px"}}>Camión en tránsito</div>
  </div>
  <div style={{ display:"flex", alignItems: "center", width:"165px", marginTop:"3px"}}>
    <div style={{backgroundColor: "#94c120", border: "1px solid black", width:"20px", height:"20px"}}></div>
    <div style={{width: "150px", height:"20px", color:"black", textAlign:"left", marginLeft:"3px"}}>Camino libre</div>
  </div>
</div>
)}
{/* Maquina 2 sensor 1 */}

        {estatusSemaf.sem20 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-130px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-130px"}} ></img>}
        <div className="regadortag2" style={{position: "relative", left:"-130px"}}>8B</div>
      </div>

      <div className="semaforodos">
      {estatusSemaf.sem19 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}} ></img>}
        <div className="regadortag2" style={{ position: "relative", left:"-50px"}}>7B</div>
      </div>
    

      <div className="semaforodos">
      {estatusSemaf.sem18 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}} ></img>}
        <div className="regadortag2" style={{ position: "relative", left:"-50px"}}>6B</div>
      </div>

      <div className="semaforodos">
      {estatusSemaf.sem17 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}} ></img>}
        <div className="regadortag2" style={{ position: "relative", left:"-50px"}}>5B</div>
      </div>

      <div className="semaforodos">
      {estatusSemaf.sem16 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}} ></img>}
        <div className="regadortag2" style={{ position: "relative", left:"-50px"}}>4B</div>
      </div>

      <div className="semaforodos">
      {estatusSemaf.sem15 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}} ></img>}
        <div className="regadortag2" style={{ position: "relative", left:"-50px"}}>3B</div>
      </div>

      <div className="semaforodos">

        <div style={{position:"absolute", display:"grid", width:"50px", top:"0px", left:"-80px", padding: "3px", zIndex: 2}}>
        <img src={cctv} alt="camara2" onClick={() => handleClick(2)} style={{ height:"40px", width:"40px", transform: "rotateY(180deg) rotate(-24deg)", cursor: "pointer"}} ></img>
        </div>

      {estatusSemaf.sem14 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}} ></img>}
        <div className="regadortag2" style={{ position: "relative", left:"-50px"}}>2B</div>
      </div>

      <div className="semaforodos">

        <div style={{position:"absolute", display:"grid", width:"50px", top:"0px", left:"-10px", padding: "3px", zIndex: 2}}>
        <img src={cctv} alt="camara3" onClick={() => handleClick(3)} style={{ height:"40px", width:"40px", transform: "rotate(336deg)", cursor: "pointer"}} ></img>
        </div>

      {/* Maquina 2 sensor 2 */}
      {estatusSensores.maquina2Sensor1 === 1 ?
        <div style={{ position: "absolute", bottom: "105px", left: "-34px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}} onMouseEnter={handleMouseEnter4} onMouseLeave={handleMouseLeave4}><div style={{borderRadius: "50%", backgroundColor: "red", width: "20px", height: "20px"}}> </div></div>
      : <div style={{ position: "absolute", bottom: "105px", left: "-34px", backgroundColor: "black", borderRadius: "5px", width: "26px", height: "26px", display: "flex", justifyContent: "center", alignItems: "center"}} onMouseEnter={handleMouseEnter4} onMouseLeave={handleMouseLeave4}><div style={{borderRadius: "50%", backgroundColor: "#94c120", width:"20px", height:"20px"}}> </div></div>}
        {showTooltip4 && (
        <div style={{position:"absolute", display:"grid", width:"170px", top:"0px", left:"-210px", backgroundColor: "white", padding: "3px", zIndex: 99}}>
          <div style={{ display:"flex", alignItems: "center", width:"165px"}}>
            <div style={{backgroundColor: "red", border: "1px solid black", width:"20px", height:"20px"}}></div>
            <div style={{height:"20px", color:"black", textAlign:"left", marginLeft:"3px"}}>Camión en tránsito</div>
          </div>
          <div style={{ display:"flex", alignItems: "center", width:"165px", marginTop:"3px"}}>
            <div style={{backgroundColor: "#94c120", border: "1px solid black", width:"20px", height:"20px"}}></div>
            <div style={{width: "150px", height:"20px", color:"black", textAlign:"left", marginLeft:"3px"}}>Camino libre</div>
          </div>
      </div>
      )}
      {/* Maquina 2 sensor 2 */}

        {estatusSemaf.sem13 ? <img src={imagenes[imagenIndex]} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}}></img> 
        : <img src={regoff} alt="regresar" style={{ height:"60px", width:"60px", transform: "rotate(180deg)", position: "relative", left:"-50px"}} ></img>}
        <div className="regadortag2" style={{ position: "relative", left:"-50px"}}>1B</div>
      </div>
          


          </div>
      
            {estatuspanic.Panic ? <img src={panicon} alt="regresar" style={{ height:"80px", width:"80px", position:"absolute", right: "5px", bottom: "3px", cursor:"pointer", filter: "drop-shadow(4px 6px 5px black)"}} onClick={enviar}></img> 
          : <img src={panicoff} alt="regresar" style={{ height:"80px", width:"80px", position:"absolute", right: "5px", bottom: "3px", cursor:"pointer", filter: "drop-shadow(4px 6px 5px black)"}} onClick={enviar}></img>}
          
        </div>
        
        <div className="Riegomidbodyley">

          <div>Tiempo de barrido: 8 seg.</div>
          <div>Cantidad de barridos: 3</div>
          <div>Presión: {(estatusflujo.Presion)} Bar</div>
          <div>Tiempo entre riego: 1 hora.</div>
          <div>Tiempo sensor: 0</div>
          <div>Aspersores simultaneos: {aspersoresonline} </div>

        </div>
      
        </div>

      </div>

      <div className="Riegobot" onClick={listadohistorico}>
      
      Historial de datos

      </div>

          <div id="listadohistorico">
          <div id="cerrarDiv" onClick={listadohistorico} style={{position: "absolute", top: "0", right: "5px", padding: "5px", cursor: "pointer", fontSize: "20px" }}>X</div>
          <div className="liststyle"><SearchComponent3/></div>
          
          
          </div>

    </div>


    );
}


  export default Riego;
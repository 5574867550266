
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import './formulario.css';
import Banner from './Componentes/banner'
import cerrar from './Imagenes/cerrar.png'
import Sidebar from './Componentes/sidebar'

const Nuevoequipo = () => {

  const [formData, setFormData] = useState({
    tipoequipo: '',
    chip: '',
    ubicacion: 'BODEGA',
    estado: '1',
    serial: ''
  })

  const [formData2, setFormData2] = useState({
    idchip: '',
    estado: 1
  })

  const Home = () => {

    window.location.href = "/inicio";
  
  };

  const reload = () => {

    window.location.href = "/nuevoequipo";
  
  };
 
  const [tipoequipo, settipoequipo] = useState([]);
    useEffect(() => {
      fetch('https://www.mine-360.com/api/ficha/gettipoequipos')
        .then(response => response.json())
          .then(data => {
            settipoequipo(data);
          })
          .catch(error => {
            console.error('Error al obtener los responsables de la API:', error);
          });
    }, []);

  const [estadoequipo, setestadoequipo] = useState([]);
    useEffect(() => {
      fetch('https://www.mine-360.com/api/ficha/getestadoequipos')
        .then(response => response.json())
          .then(data => {
            setestadoequipo(data);
          })
        .catch(error => {
          console.error('Error al obtener los responsables de la API:', error);
        });
    }, []);

  const [faena, setfaena] = useState([]);
    useEffect(() => {
      fetch('https://www.mine-360.com/api/ficha/getfaenas')
        .then(response => response.json())
          .then(data => {
            setfaena(data);
        })
        .catch(error => {
          console.error('Error al obtener los responsables de la API:', error);
        });
  }, []);

  const [chip, setchip] = useState([]);
    useEffect(() => {
        fetch('https://www.mine-360.com/api/ficha/getchips')
          .then(response => response.json())
            .then(data => {
              setchip(data);
          })
          .catch(error => {
            console.error('Error al obtener los responsables de la API:', error);
          });
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'chip') {
      const selectedId = e.target.options[e.target.selectedIndex].id;
      setFormData2({
        ...formData2,
        idchip: selectedId
      });
    }
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    try {
      const response = await fetch('https://www.mine-360.com/api/ficha/equipoin', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
      });

      if (response.ok){
        const response2 = await fetch('https://www.mine-360.com/api/ficha/updatechip', {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(formData2)
        });

        if (response2.ok){
          Swal.fire({
            title: "Guardado!",
            confirmButtonText: 'OK',
            icon: "success",
          })

          setTimeout(() => {
            reload();
          }, 2000); // Wait for 3 seconds before calling reload()

        } else {

          Swal.fire({
            title: "Error al enviar los datos1!",
            confirmButtonText: 'Atras',
            icon: "error",
          })
          console.error('Error al enviar los datos');
        }
      } else 
        if (response.status === 410){
          Swal.fire({
            title: "Serial ya en uso",
            confirmButtonText: 'Atras',
            icon: "error",
          })
          console.error('Serial ya en uso');
      }
    } catch (error) {
      console.error('Error al enviar los datos2: ', error);
      Swal.fire({
        title: "Error al enviar los datos2!",
        confirmButtonText: 'Atras',
        icon: "error",
      })
    }
  };

  const enviar = (e) => {
    e.preventDefault();
      if ( formData.tipoequipo === '' || formData.ubicacion  === ''  || formData.estado  === ''){
        Swal.fire({
          title: "Rellene los campos faltantes!",
          confirmButtonText: 'OK',
          icon: "error",
        })
      }else{
      Swal.fire({
        title: "Quieres guardar los cambios?",
        showCancelButton: true,
        confirmButtonText: "Guardar", 
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit();
        }
      });  
      } 
  }

  
return (

<div className="nuevoequipo">

    <Banner/>
    <Sidebar/>

    <div className="nuevoequipo-body">

    <img src={cerrar} alt="regresar" style={{ height:"40px", width:"40px", position:"absolute", right:"5px", top:"9vh", zIndex:"2", cursor:"pointer"}} onClick={Home}></img>

        <div id="formularionuevoequipo" className="formularionuevoequipo">

            <div className="formularionuevoequipo-1">

            <div className="ordenar" >
            <label>TIPO DE EQUIPO:</label>
              <select className="estilo" name="tipoequipo" onChange={handleChange}>
                <option value={formData.tipoequipo} disabled defaultValue hidden>Seleccionar</option>
                {tipoequipo.map(tipoequipo => (
                <option key={tipoequipo.id_tipoequipo} value={tipoequipo.idtipoequipo}>{tipoequipo.nombre}</option>))}
              </select>
            </div>
            
            <div className="ordenar">
              <label for="serial">SERIAL:</label>
              <input className="estilo" name="serial" onChange={handleChange} value={formData.serial} type="text"/>
            </div>

            <div className="ordenar" >
            <label>UBICACION DEL EQUIPO:</label>
              <select className="estilo" name="ubicacion" onChange={handleChange}>
                <option value={formData.faena} disabled defaultValue hidden>BODEGA</option>
                {faena.map(faena => (
                <option disabled key={faena.id_tipoequipo} value={faena.nombre}>{faena.nombre}</option>))}
              </select>
            </div>

            <div className="ordenar" >
            <label>N° DE CHIP ASOCIADO AL EQUIPO:</label>
            <select className="estilo" name="chip" onChange={handleChange}>
            <option value={formData.chip} disabled defaultValue hidden>Seleccionar</option>
            {chip.filter(chip => chip.estado === "ACTIVO DESINSTALADO").map(filteredChip => (
              < option key={filteredChip.idchip} value={filteredChip.chip} id={filteredChip.idchip}>{filteredChip.imei}</option>
            ))}
            </select>
            </div>



                <div className="ordenar" >
                <label>ESTADO DEL EQUIPO:</label>
                <select className="estilo" name="estado" onChange={handleChange}>
                <option value={formData.estadoequipo} disabled defaultValue hidden>ACTIVO</option>
                {estadoequipo.map(estadoequipo => (
                <option disabled key={estadoequipo.id_estadoequipo} value={estadoequipo.idestado}>{estadoequipo.tipoestado}</option>))}
                </select>
                </div>

            </div>

            <div className="formularionuevoequipo-2">

              <button type="Submit" className="boton" onClick={Home}>Atras</button>
              <button type="Submit" className="boton" onClick={enviar}>Enviar</button>

            </div>

        </div>
     
    </div>

</div>
  );
}

export default Nuevoequipo;

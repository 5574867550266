import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import moment from 'moment';
import editar from '../Imagenes/edit.png'
import '../listado.css'
import '../formulario.css'

const SearchComponent = () => {
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState("")
  const [selectedItem, setSelectedItem] = useState(null)

  const URL = 'http://18.207.128.95:8080/api/ficha/getequipos'

  const showData = async () => {
    const response = await fetch(URL)
    const data = await response.json()
    setUsers(data)
  }

  const searcher = (e) => {
      setSearch(e.target.value)   
  }

  const results = !search ? users : users.filter((dato) => {
    return Object.values(dato).some(value =>
        value.toString().toLowerCase().includes(search.toLowerCase())
    )
  })

  const [formData, setFormData] = useState({
    idequipo: '',
    tipoequipo: '',
    chip: '',
    ubicacion: '',
    estado: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
  };

  const editHandler = (idequipo) => {
    document.getElementById("updateequipos").style.display = "grid";
    document.getElementById("tablaequipos").style.display = "none";
    document.getElementById("buscarequipos").style.display = "none";
    const selectedUser = users.find(user => user.idequipo === idequipo)
    setSelectedItem(selectedUser);

    // Setear los valores seleccionados en el formData
    setFormData({
      idequipo: selectedUser.idequipo,
      tipoequipo: selectedUser.idtipoequipo,
      chip: selectedUser.chip,
      ubicacion: selectedUser.ubicacion,
      estado: selectedUser.idestado
    });

  }

  const cancelar = () => {
    window.location.href = "/listadoequipos";
  }

  useEffect(() => {
    showData()
  }, [])

  const [tipoequipo, settipoequipo] = useState([]);
    useEffect(() => {
      fetch('http://18.207.128.95:8080/api/ficha/gettipoequipos')
        .then(response => response.json())
          .then(data => {
            settipoequipo(data);
          })
          .catch(error => {
            console.error('Error al obtener los responsables de la API:', error);
          });
    }, []);

    const [estadoequipo, setestadoequipo] = useState([]);
    useEffect(() => {
      fetch('http://54.162.236.190:4000/api/ficha/getestados')
        .then(response => response.json())
          .then(data => {
            setestadoequipo(data);
          })
        .catch(error => {
          console.error('Error al obtener los responsables de la API:', error);
        });
    }, []);

  const [faena, setfaena] = useState([]);
    useEffect(() => {
      fetch('http://18.207.128.95:8080/api/ficha/getfaenas')
        .then(response => response.json())
          .then(data => {
            setfaena(data);
        })
        .catch(error => {
          console.error('Error al obtener los responsables de la API:', error);
        });
  }, []);

  const [imei, setimei] = useState([]);
    useEffect(() => {
        fetch('http://18.207.128.95:8080/api/ficha/getchips')
          .then(response => response.json())
            .then(data => {
              setimei(data);
          })
          .catch(error => {
            console.error('Error al obtener los responsables de la API:', error);
          });
  }, []);

  const enviar = (e) => {
    e.preventDefault();
      if ( formData.tipoequipo === '' || formData.ubicacion  === ''  || formData.estado  === ''){
        Swal.fire({
          title: "Rellene los campos faltantes!",
          confirmButtonText: 'OK',
          icon: "error",
        })
      }else{
      Swal.fire({
        title: "Quieres guardar los cambios?",
        showCancelButton: true,
        confirmButtonText: "Guardar", 
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit();
        }
      });  
      } 
  }
    
  const handleSubmit = async (e) => {
    try {
      const response = await fetch('http://18.207.128.95:8080/api/ficha/updateEquipo', {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        Swal.fire({
          title: "Guardado!",
          confirmButtonText: '<a href="./listadoequipos">OK</a>',
          icon: "success",
        })
      } else {
        Swal.fire({
          title: "Error al guardar los datos!",
          confirmButtonText: 'Atras',
          icon: "error",
        })
      }
    } catch (error) {
      console.error('Error al guardar los datos: ', error);
      Swal.fire({
        title: "Error al enviar los datos!",
        confirmButtonText: 'Atras',
        icon: "error",
      })
    }
  };

  const fechaFormateada = (fecha) => {
    return moment(fecha).format('YYYY-MM-DD HH:mm');
  };

  return (
    <div className='container'>

      <div className='updateequipos' id='updateequipos'>

        <div className="ordenar" >
          <label>TIPO DE EQUIPO:</label>
          <select id="estilo" name="tipoequipo" onChange={handleChange}>
            <option value={formData.tipoequipo} disabled selected hidden>{selectedItem?.tipoequipo}</option>
              {tipoequipo.map(tipoequipo => (
              <option key={tipoequipo.id_tipoequipo} value={tipoequipo.idtipoequipo}>{tipoequipo.nombre}</option>))}
          </select>
        </div>

        <div className="ordenar" >
            <label>UBICACION DEL EQUIPO:</label>
              <select id="estilo" name="ubicacion" onChange={handleChange}>
                <option value={formData.faena} disabled selected hidden>{selectedItem?.ubicacion}</option>
                {faena.map(faena => (
                <option key={faena.id_tipoequipo} value={faena.nombre}>{faena.nombre}</option>))}
              </select>
            </div>

            <div className="ordenar" onChange={handleChange}>
            <label>N° DE CHIP ASOCIADO AL EQUIPO:</label>
              <select id="estilo" name="chip" >
                <option value={formData.imei} disabled selected hidden>{selectedItem?.chip}</option>
                {imei.map(imei => (
                <option key={imei.id_chip} value={imei.imei}>{imei.imei}</option>))}
              </select>
            </div>

                <div className="ordenar" onChange={handleChange}>
                <label>ESTADO DEL EQUIPO:</label>
                <select id="estilo" name="estado" >
                <option value={formData.estadoequipo} disabled selected hidden>{selectedItem?.estado}</option>
                {estadoequipo.map(estadoequipo => (
                <option key={estadoequipo.id_estadoequipo} value={estadoequipo.idestado}>{estadoequipo.tipoestado}</option>))}
                </select>
                </div>

                <div className="formularionuevoequipo-2">

                <button type="Submit" className="boton" onClick={cancelar}>Cancelar</button>
                <button type="Submit" className="boton" onClick={enviar}>Guardar</button>

                </div>

      </div>
      
      <input id='buscarequipos' value={search} onChange={searcher} type="text" placeholder='Buscar' className='form-control'/>
      <table id='tablaequipos' className='table table-striped table-hover mt-1 shadow-lg'>
        <thead>
          <tr>
            <th>TIPO DE EQUIPO</th>
            <th>SERIAL</th>
            <th>IMEI</th>
            <th>UBICACION</th>
            <th>ULTIMA ACTUALIZACION</th>
            <th>ESTADO</th>
            <th>EDITAR</th>
          </tr>
        </thead>
        <tbody>
          {results.map((user) => (
            <tr key={user.id}>
              <td>{user.tipoequipo}</td>
              <td>{user.serial ? user.serial : 'N/A'}</td>
              <td>{user.chip}</td>
              <td>{user.ubicacion}</td>
              <td>{fechaFormateada(user.fecha_ult_modific)}</td>
              <td className={user.estado === 'ACTIVO' ? 'text-success' : user.estado === 'OBSOLETO' ? 'text-danger' : 'text-muted'} style={{ borderRadius:"15px"}}>{user.estado}</td>
              <td>
                <div onClick={() => editHandler(user.idequipo)}><img src={editar}  style={{ height:"20px", width:"20px", cursor:"pointer"}} alt="Editar" /></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SearchComponent
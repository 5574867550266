import React from 'react';
import logo from '../Imagenes/Logo.png'

const banner2 = () => {
  const banner2 = {
    height: "8vh",
    width: "100%",
    display: "flex",
    position: "absolute",
    top: "0",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    boxShadow: "1px 3px 10px 1px rgba(15, 15, 15, 1)",
    padding: "10px"
  };

  return (
    <div style={banner2}>
      <img src={logo} alt="banner" />
    </div>
  );
};

export default banner2;
import React from 'react';
import logo from '../Imagenes/Logo.png'

const banner = () => {
  const banner = {
    height: "8vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    boxShadow: "1px 3px 10px 1px rgba(15, 15, 15, 1)",
    padding: "10px"
  };

  return (
    <div style={banner}>
      <img src={logo} onClick={() => window.location.href = "./inicio"} alt="banner" />
    </div>
  );
};

export default banner;